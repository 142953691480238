import React from 'react'
import Layout from '../components/layout'
import image404 from '../assets/images/404.jpg';

const NotFoundPage = () => (
    <Layout>
        <div className="page-not-found-component generic-content-component">
            <div className="component-wrapper">
                <img src={image404} alt="404"/>
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
